import { useState, useEffect } from 'react'
import dynamic from 'next/dynamic'
import Icon from '@/components/icon/icon'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { addForwardSlash } from '@/lib/helpers'
import Link from 'next/link'
import { styled } from 'stitches.config'
import { ctaClickGTM } from '@/lib/gtm'
import { HeroBackgroundImage } from '@/components/image'
import { prepareCollectionData } from '@/lib/product-helper'

const GridLines = dynamic(() => import('@/components/common/grid-lines'))
const ProductList = dynamic(() => import('@/components/product/product-list'))

const HeroImage = styled(HeroBackgroundImage, {
  height: '626px',
  '@md': { height: '650px' },
  '@lg': { height: '650px' },
  '@xl': { height: '800px' },
  variants: {
    useMediumImage: {
      true: {
        height: '450px',
        '@md': { height: '450px' },
        '@lg': { height: '450px' },
        '@xl': { height: '600px' },
      },
    },
  },
})

const HeroSeparateTextArea = ({ VideoBackground, ...props }) => {
  const [products, setProducts] = useState([])
  const textColor = props?.textColor ? props?.textColor : 'Black'
  //GTM
  const handleCtaClick = (text, url) => {
    ctaClickGTM(text, url)
  }
  //

  const mobileBg =
    props.mobileBackgroundImage?.url ||
    props.tabletBackgroundImage?.url ||
    props.backgroundImage?.url
  const tabletBg =
    props.tabletBackgroundImage?.url ||
    props.backgroundImage?.url ||
    props.mobileBackgroundImage?.url
  const desktopBg =
    props.backgroundImage?.url ||
    props.tabletBackgroundImage?.url ||
    props.mobileBackgroundImage?.url

  useEffect(() => {
    if (props?.productCollection?.items && props?.productCollection?.items?.length > 0) {
      let productSkus = props?.productCollection?.items.map((item) => {
        return {
          sku: item.productId
        }
      })
      prepareCollectionData(productSkus).then((data) => {
        setProducts(data)
      })
    }
  }, [props?.productCollection?.items])
  
  return (
    <div
      className={`hero hero--separate-text-area relative ${
        textColor == 'Black' ? 'bg-white' : 'bg-black'
      }`}
    >
      <HeroImage
        useMediumImage={props.useMediumImage}
        imageProps={{
          priority: true,
          quality: 85,
          objectFit: 'cover',
          srcMap: {
            mobile: mobileBg,
            tablet: tabletBg,
            desktop: desktopBg,
          },
          height: props?.useMediumImage ? 450 : 1620,
          width: 800
        }}
      >
        {props.useGridLines && <GridLines gridLinesColor={props.gridLinesColor} />}
        {/* Show white background when autoplay to prevent bg image flash */}
        {props.useVideoBackground && props.autoplay && (
          <div className="absolute inset-0 bg-white"></div>
        )}
        {props.useVideoBackground && (
          <VideoBackground video={props.video} autoplay={props.autoplay} />
        )}
      </HeroImage>
      {products?.length > 0 && (
        <div className="relative z-10">
          <div
            className="absolute right-65 bottom-55 bottom-30 right-20 p-1 rounded-full circle-section cursor-pointer"
            onTouchStart={() => {
              props.showProductList()
            }}
            onMouseEnter={() => {
              props.showProductList()
            }}
          >
            <div
              className={`${
                props.showProductMenu !== null
                  ? props.showProductMenu
                    ? ''
                    : 'plus-icon-on-show'
                  : 'plus-icon-on-reload'
              }`}
            >
              <Icon
                className={`cursor-pointer text-white fill-current w-7 h-7`}
                viewBox={`0 0 36 36`}
                size={36}
                icon="plus-circle"
              />
            </div>
          </div>
          <ProductList
            showProductMenu={props.showProductMenu}
            setShowProductMenu={props.setShowProductMenu}
            products={products}
          />
        </div>
      )}
      {Boolean(props.headline) && (
        <div
          className={`w-full px-6 xl:px-32 text-center container z-20 text-black ${
            props.eyebrow || props.bodyText || (props.ctaLeftUrl && props.ctaLeftText)
              ? 'headline-padding'
              : ''
          }`}
        >
          {props.eyebrow && (
            <p
              className={`${
                props.useSecondaryFontFamily ? 'about-eyebrow' : ''
              } mb-4 text-xsm font-tertiary font-normal uppercase ${
                textColor == 'Black' ? 'text-gwBlack' : 'text-white'
              }`}
            >
              {props.eyebrow}
            </p>
          )}
          <h1
            className={`${
              props.useSecondaryFontFamily
                ? 'font-secondary font-semibold font-60 font-70 headline-l-height'
                : 'font-primary font-normal font-65 xl:text-6xl headline-line-height'
            } md:px-20 xl:px-0 font-40 font-50 tracking-tight break-words sm:break-normal ${
              textColor == 'Black' ? 'text-black' : 'text-white'
            }`}
          >
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{props.headline}</ReactMarkdown>
          </h1>
          {props.bodyText && (
            <div
              className={`font-primary font-normal text-center px-6 mt-5 text-base md:text-smd font-21 font-25 leading-5 md:leading-26 lg:leading-7 xl:leading-8 lg:tracking-tight ${
                textColor == 'Black' ? 'text-black' : 'text-white'
              }`}
            >
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>{props.bodyText}</ReactMarkdown>
            </div>
          )}

          <div className="mt-7 button-group-wrap flex-col md:flex-row flex items-center justify-center">
            {props.ctaLeftUrl && props.ctaLeftText && (
              <Link
                href={addForwardSlash(props.ctaLeftUrl)}
                onClick={() => {
                  handleCtaClick(props.ctaLeftText, props.ctaLeftUrl)
                }}
                className={`btn flex items-center rounded-full relative px-5 py-18 text-smd lg:whitespace-no-wrap leading-18 font-medium mb-5 md:mb-0 ${
                  textColor == 'Black'
                    ? 'bg-black text-white hover:bg-gray-800 active:bg-gray-700'
                    : 'text-black bg-white'
                }`}
              >
                <span className={`${props.ctaLeftIcon ? 'pr-4' : ''}`}>{props.ctaLeftText}</span>
                {props.ctaLeftIcon && (
                  <Icon
                    className={`cursor-pointer text-newOrange fill-current text-2xl flex-shrink-0`}
                    viewBox={`0 0 12 12`}
                    size={12}
                    fill={'#E36F22'}
                    icon="arrow"
                  />
                )}
              </Link>
            )}
            {props.ctaRightUrl && props.ctaRightText && (
              <Link
                href={addForwardSlash(props.ctaRightUrl)}
                onClick={() => {
                  handleCtaClick(props.ctaRightText, props.ctaRightUrl)
                }}
                className={`btn flex items-center justify-between rounded-full relative px-5 py-18 text-smd lg:whitespace-no-wrap leading-18 font-medium active:bg-gray-700 ml-0 md:ml-10 xl:ml-15 ${
                  textColor == 'Black'
                    ? 'bg-black text-white hover:bg-gray-800 active:bg-gray-700'
                    : 'text-black bg-white'
                }`}
              >
                <span className={`${props.ctaRightIcon ? 'pr-4' : ''}`}>{props.ctaRightText}</span>
                {props.ctaRightIcon && (
                  <Icon
                    className={`cursor-pointer text-newOrange fill-current text-2xl flex-shrink-0`}
                    viewBox={`0 0 12 12`}
                    size={12}
                    fill={'#000000'}
                    icon="arrow"
                  />
                )}
              </Link>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default HeroSeparateTextArea
